import { TrainingNotGoingReasonEnum, IMatchStats } from "~/utils/api.interfaces.enums";
import { ThemeContextType } from "~/utils/theme-provider";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

/**
 * Shared color configurations for all charts and UI elements
 */
export const CHART_COLORS = {
  primary: '#007AFF',
  secondary: '#AF52DE',
  accent: '#D7503D',
  neutral: '#BBC3CF',
  palette: {
    light: ['#8F8F91', '#FFBF29', '#FF7429', '#974DDE', '#3C4BD9', '#CACACB'],
    dark: ['#8F8F91', '#FFBF29', '#FF7429', '#974DDE', '#3C4BD9', '#CACACB']
  }
} as const;

export const labelsNotGoing = Object.values(TrainingNotGoingReasonEnum);

/**
 * Base chart configuration factory
 */
export const baseChartConfig = (theme: ThemeContextType[0]) => ({
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    intersect: false,
    mode: 'index'
  },
  plugins: {
    legend: {
      display: false,
      position: 'bottom' as const,
      labels: {
        color: theme === 'dark' ? '#EEF0F3' : '#000'
      }
    }
  },
  scales: {
    x: {
      grid: {
        color: theme === 'dark' ? '#2A3342' : '#EEF0F3'
      },
      ticks: {
        color: theme === 'dark' ? '#EEF0F3' : '#000'
      }
    },
    y: {
      grid: {
        color: theme === 'dark' ? '#2A3342' : '#EEF0F3'
      },
      ticks: {
        color: theme === 'dark' ? '#EEF0F3' : '#000'
      }
    }
  }
});

/**
 * Mini chart configuration factory
 */
export const miniChartConfig = (theme: ThemeContextType[0]) => ({
  ...baseChartConfig(theme),
  plugins: {
    legend: {
      display: false
    },
    tooltip: {
      enabled: true
    }
  },
  scales: {
    x: {
      display: false
    },
    y: {
      display: false
    }
  }
});

/**
 * Utility functions
 */
export const formatShortDate = (dateString: string) => {
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, {
    month: "short",
    day: "numeric",
  });
};

export const formatScore = (match: IMatchStats) => {
  if (!match.score) return "";
  const { team, opponent } = match.score;
  if (team === undefined || opponent === undefined) return "";
  return `${team}-${opponent}`;
};

export const getChartOptions = (theme: ThemeContextType[0]) => ({
  plugins: {
    legend: {
      position: "bottom" as const,
      labels: {
        color: theme === "dark" ? "#EEF0F3" : "#000",
      },
    },
  },
});