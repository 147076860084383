import { Line, Bar, Pie } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler
);

/**
 * Base Chart Component
 *
 * A wrapper component for Chart.js that provides unified configuration and styling.
 * Supports line, bar, and pie charts with consistent theming and responsive behavior.
 */

/**
 * Props for BaseChart component
 */
type BaseChartProps = {
  type: "line" | "bar" | "pie"; // Type of chart to render
  data: any; // Chart data configuration
  options: any; // Chart options configuration
  height?: number; // Optional height in pixels
  width?: string; // Optional width (CSS value)
};

export default function BaseChart({
  type,
  data,
  options,
  height,
  width,
}: BaseChartProps) {
  // Map chart type to corresponding Chart.js component
  const ChartComponent = {
    line: Line,
    bar: Bar,
    pie: Pie,
  }[type];

  return (
    <div style={{ height, width }}>
      <ChartComponent data={data} options={options} />
    </div>
  );
}
